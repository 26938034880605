import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import {
  Typography,
  Container,
  Row,
  Col,
  Redactor,
  AspectRatio,
  Picture,
  Spacer,
} from "@hurleymc/components"
import { selectFirst } from "@hurleymc/sdk/selectors"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/page-builder"
import { DefaultProfileImage } from "../components/default-profile-image"
import theme from "../theme"

const PersonImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 50%;
`

const PersonImage = styled.div`
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const PersonDetails = styled(Col)`
  align-self: center;
  text-align: center;
`

const BioBlock = styled(Col)`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0.25rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

const BioBlockTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const PersonName = styled(Typography)`
  margin-top: 1rem;
`

const PersonHeader = ({ ...props }) => {
  const {
    bioBlocks,
    contactImage: originalContactImage,
    images: originalImages,
    fullName,
    honorificSuffix,
  } = props

  // ? Not sure why the types aren't correct
  const contactImages = originalContactImage
  const contactImage = selectFirst(contactImages)
  const images = originalImages
  const image = selectFirst(images)

  const personDetailsRowStyle =
    bioBlocks && bioBlocks.length > 0 ? {} : { justifyContent: "center" }

  return (
    <>
      {image && (
        <Picture
          alt={image.title}
          sources={[
            {
              media: `(min-width: ${theme.breakpoints.xl}px)`,
              srcSet: image.xl,
            },
            {
              media: `(min-width: ${theme.breakpoints.lg}px)`,
              srcSet: image.lg,
            },
            {
              media: `(min-width: ${theme.breakpoints.md}px)`,
              srcSet: image.lg,
            },
          ]}
          src={image.sm || image.url}
        />
      )}
      <Spacer margin="2rem .5rem">
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <Row style={personDetailsRowStyle}>
                <PersonDetails lg={1 / 3}>
                  <PersonImageWrapper>
                    <AspectRatio ratio="1:1">
                      <PersonImage>
                        {contactImage.url ? (
                          <Picture
                            alt={contactImage.title}
                            sources={[
                              {
                                media: `(min-width: ${theme.breakpoints.lg}px)`,
                                srcSet: contactImage.lg,
                              },
                            ]}
                            src={contactImage.sm || contactImage.url}
                          />
                        ) : (
                          <DefaultProfileImage />
                        )}
                      </PersonImage>
                    </AspectRatio>
                  </PersonImageWrapper>
                  <PersonName variant="heading-3">{fullName}</PersonName>
                  {honorificSuffix && (
                    <Typography variant="heading-4">
                      {honorificSuffix}
                    </Typography>
                  )}
                </PersonDetails>
                {bioBlocks &&
                  bioBlocks.length > 0 &&
                  bioBlocks.map((block, idx) => (
                    <BioBlock md={1 / 3} key={`bio-block-${idx}`}>
                      <BioBlockTitle variant="heading-4">
                        {block.blockTitle}
                      </BioBlockTitle>
                      <Redactor
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: block.blockContent.content,
                        }}
                      />
                    </BioBlock>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </>
  )
}

const PagePerson = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  return (
    <Layout>
      <SEO title={entry.title} />
      <PersonHeader {...entry} />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query PagePersonQuery($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyGme", id: $id) {
        __typename
        title
        uri
        ... on Craft_contact_contact_Entry {
          contactImage {
            ... on Craft_images_Asset {
              title
              url
              sm: url(transform: "contactSquareSm")
              lg: url(transform: "contactSquareLg")
              xl: url(transform: "contactSquareXl")
            }
          }
          fullName
          honorificSuffix
          jobTitle
          phoneNumber
          email
          images {
            ... on Craft_images_Asset {
              title
              url
              sm: url(transform: "splashSm")
              lg: url(transform: "splashLg")
              xl: url(transform: "splashXl")
            }
          }
          bioBlocks {
            ... on Craft_bioBlocks_featuredBlocks_BlockType {
              blockTitle
              blockContent
            }
          }
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default PagePerson
